import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import { Container, Row, Col, Badge, Modal, CardDeck, Card, Button, ListGroup } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import firebase from "gatsby-plugin-firebase"
import { slugify } from "../helpers"
import { useQueryParam, StringParam } from "use-query-params"
import { GoogleData } from "../components/dataSource"

import SignUpComponent from "../components/sign-up"
import Checkmark from "../images/icons/checkmark.svg"

const CallForEquityPage = ({ data }) => {
  const monthNames = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
  const [show, setShow] = useState(false)
  const [orgsAlpha, setOrgsAlpha] = useState([])
  const [orgsSorted, setOrgsSorted] = useState([])
  const [modalOrg, setModalOrg] = useState({ name: null, slug: null })

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [orgsData, setOrgsData] = useState({})
  const [dashboardData, setDashboardData] = useState({
    signed: 0,
    registered: 0,
    c1c2completes: [],
    complete: [],
  })

  const [commitments, setCommitments] = useState([])

  const [organization, setOrgParam] = useQueryParam("organization", StringParam)

  useEffect(() => {
    if (organization && !show) {
      handleClick(slugNames[organization])
    } else if (!organization && show) {
      handleClose()
    }
  }, [organization])

  //get responses from firebase, and set default response to no-response if not yet saved
  useEffect(() => {
    const orgsRef = firebase.database().ref("/orgs/")

    orgsRef.on("value", snapshot => {
      const val = snapshot.val()
      setOrgsData(val)
    })
  }, [])

  const updateDashboardData = (key, value) => {
    setDashboardData(prevState => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }

  //set response/value
  const responses = {
    "no-response": "Not started",
    "not-started": "Planned start date",
    "3-months": "Intend to complete in 3 months",
    "6-months": "Intend to complete in 6 months",
    completed: "Completed",
  }

  const commitmentData = data.allContentfulCallForEquityPage.edges[0].node.commitments

  const raw = GoogleData()
  const signatories = raw.reduce(function (r, a) {
    r[a.company] = r[a.company] || []
    r[a.company].push(a)
    return r
  }, Object.create(null))

  useEffect(() => {
    const alphabetized = Object.keys(signatories).sort(function (a, b) {
      var textA = a.toUpperCase()
      var textB = b.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })

    setOrgsAlpha(alphabetized)
    setOrgsSorted(alphabetized)
  }, [])

  //make object of slug/name pairs
  const slugNames = {}
  Object.keys(signatories).forEach(key => {
    const slug = slugify(key)
    slugNames[slug] = key
  })

  const timestampToDate = timestamp => {
    const options = { year: "numeric", month: "short", day: "2-digit" }
    const dateString = timestamp.split(" ")[0]
    const dateParts = dateString.split("/")
    const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
    const _resultDate = new Intl.DateTimeFormat("en-GB", options).format(dateObject)

    // console.log(_resultDate)

    return _resultDate
  }

  //update dashboard data
  useEffect(() => {
    //count c1 and c2 completes
    const c1c2completes = Object.keys(orgsData)
      .filter(org => {
        return orgsData[org]["commitment-01"] === "completed" && orgsData[org]["commitment-02"] === "completed"
      })
      .map(slug => {
        return orgsData[slug].companyName
      })

    // console.log(c1c2completes);
    const completed = Object.keys(orgsData)
      .filter(org => {
        const count = orgsData[org] && orgsData[org].agencyType === "agency" ? 12 : 15
        let completes = Object.keys(orgsData[org]).filter(key => {
          return orgsData[org][key] === "completed"
        })
        return count === completes.length
      })
      .map(slug => {
        return orgsData[slug].companyName
      })

    const registered = Object.keys(orgsData).map(org => orgsData[org].companyName)
    console.log(registered)

    updateDashboardData("signed", orgsAlpha.length)
    updateDashboardData("registered", registered)
    updateDashboardData("c1c2completes", c1c2completes)
    updateDashboardData("complete", completed)
  }, [orgsData, orgsAlpha])

  const handleClick = name => {
    const slug = slugify(name)
    setOrgParam(slug)
    setModalOrg({ name, slug })
    handleShow()
  }

  const handleBack = () => {
    setOrgParam("")
    handleClose()
  }

  const processAnswer = (orgData, key) => {
    //check if orgData
    if (!orgData) {
      return "No response"
    }

    //get response data
    const responseData = orgData[key]
    const response = orgData ? responses[orgData[key]] : null
    const url = orgData ? orgData[key + "-note"] : null

    const links = orgData ? orgData[key + "-link-count"] : null

    if (responseData === "completed" && links) {
      return (
        <>
          {[...Array(links)].map((e, i) => {
            let labelName = `${key}-link-${i}-label`
            let urlName = `${key}-link-${i}-url`

            //handle older data
            if (i === 0 && orgData[key + "-note"]) {
              labelName = key + "-label"
              urlName = key + "-note"
            }
            return (
              <p key={urlName} className="text-left mb-0 py-1">
                <a className="has-check" target="_blank" rel="noreferrer" href={orgData[urlName]}>
                  <Checkmark className="checkmark mr-1" />
                  <span>{orgData[labelName]}</span>
                </a>
              </p>
            )
          })}
        </>
      )
    } else if (responseData === "completed" && url) {
      return (
        <p className="mb-1">
          <a className="btn btn-primary btn-sm" href={url} target="_blank" rel="noreferrer">
            <Checkmark className="checkmark mr-1" />
            View
          </a>
        </p>
      )
    } else if (responseData === "completed") {
      return (
        <>
          <p className="mb-1 text-primary">
            <Checkmark className="checkmark mr-1" />
            Complete
          </p>
        </>
      )
    } else if (responseData === "not-started") {
      const month = orgData[key + "-startMonth"]
      const year = orgData[key + "-startYear"]
      return (
        <p>
          {response}
          <br />
          <strong>
            {monthNames[month - 1]}, {year}
          </strong>
        </p>
      )
    } else if (responseData) {
      return response
    } else {
      return "No response"
    }
  }

  const handleSort = type => {
    console.log("clicked", type)
    if (type === "reset") {
      setOrgsSorted(orgsAlpha)
      return
    }

    const selectedOrgs = dashboardData[type]
    console.log(selectedOrgs)
    setOrgsSorted(selectedOrgs)

    //copy sorted into new var
    // grab elements, pop, and place
    // animate?
  }

  const orgLastUpdated = org => {
    const lastUpdated = orgsData[org] ? orgsData[org].timestamp : null
    // console.log(lastUpdated)

    const date = new Date(lastUpdated).toLocaleDateString("en-GB")

    // console.log(date)

    return lastUpdated ? timestampToDate(date) : "--"
  }

  const orgCompleted = org => {
    let completes = 0
    const orgData = orgsData[org] || null
    const count = orgsData[org] && orgsData[org].agencyType === "agency" ? 12 : 15

    if (!orgData) {
      return null
    }

    Object.entries(orgData).forEach(([key, value]) => {
      if (value === "completed") {
        // console.log(value)
        completes++
      }
    }) // "foo: bar", "baz: 42"

    return `${completes} / ${count}`
  }

  useEffect(() => {
    const count = orgsData[modalOrg.slug] && orgsData[modalOrg.slug].agencyType === "agency" ? 12 : 15
    const commitments = commitmentData.slice(0, count)
    setCommitments(commitments)
  }, [modalOrg])

  return (
    <Layout>
      <SEO title="Call for Equity" description="Sign the Call for Equity. Track your organization's progress." />

      <Modal className="modal-page" backdrop={false} show={show} onHide={handleBack}>
        <Modal.Header className="bg-dark text-white">
          <Container>
            <Row>
              <Col>
                {modalOrg ? (
                  <>
                    <Modal.Title className="d-inline">
                      <p className="mb-0">{modalOrg.name}</p>
                    </Modal.Title>
                    <ul className="mb-0 list-inline">
                      <li className="list-inline-item">
                        <strong>signed:</strong> {modalOrg.name ? timestampToDate(signatories[modalOrg.name][0].timestamp) : ""}
                      </li>
                      <li className="list-inline-item">
                        <strong>updated:</strong> {modalOrg.slug ? orgLastUpdated(modalOrg.slug) : "--"}
                      </li>
                      <li className="list-inline-item">
                        <strong>completed:</strong> {modalOrg.slug ? orgCompleted(modalOrg.slug) : "--"}
                      </li>
                    </ul>
                  </>
                ) : (
                  "loading..."
                )}
              </Col>
              <Col xs="4" className="text-right">
                <Button onClick={handleBack} size="sm">
                  Go Back
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container className="pb-3">
            <h3 className="h5 mb-2">Signatories</h3>
            <Row className="justify-content-start mb-3">
              {modalOrg.name
                ? signatories[modalOrg.name].map((signee, idx) => (
                    <Col xs="6" md="3" xl="2" key={idx} className="mb-2">
                      <h4 className="h6 mb-1">
                        {signee.firstName} {signee.lastName}
                      </h4>
                      <p>{signee.role_Title}</p>
                    </Col>
                  ))
                : null}
            </Row>

            <Row>
              <Col>
                <ListGroup>
                  <div className="list-group-item">
                    <Row className="list-group-header">
                      <Col className="col-num">#</Col>
                      <Col xs="7" sm="8">
                        <strong>Commitment</strong>
                      </Col>
                      <Col xs="4" sm="3" xl={{ span: 2, offset: 1 }}>
                        <strong>Status</strong>
                      </Col>
                    </Row>
                  </div>

                  {commitments.map((commitment, index) => {
                    const key = "commitment-" + ("0" + (index + 1)).slice(-2)
                    const orgData = orgsData[modalOrg.slug] || null

                    return (
                      <ListGroup.Item key={index}>
                        <Row>
                          <Col className="col-num">{index + 1}</Col>
                          <Col xs="7" sm="8">
                            <div
                              className="commitment-html"
                              dangerouslySetInnerHTML={{
                                __html: commitment.commitment.childMarkdownRemark.html,
                              }}
                            />
                          </Col>
                          <Col xs="4" sm="3" xl={{ span: 2, offset: 1 }}>
                            {processAnswer(orgData, key)}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    )
                  })}
                </ListGroup>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <section className="py-4 py-lg-5 bg-dark text-white">
        <Container>
          <h1 className="display-4 mb-3">Call for Equity</h1>
          <h2 className="h1 mb-4">
            <Badge className="badge-lg" variant="primary">
              {orgsAlpha.length}
            </Badge>{" "}
            agencies and clients answered The Call.
          </h2>
          <Row>
            <Col md="8">
              <div className="mb-4">
                <p className="lead">
                  In June 2020, POCAM published <strong>The Call for Equity</strong>: 15 demands to create justice, equity and inclusion in
                  the industry for the Black, Indigenous and PoC professionals. Recognizing the influence and financial power concentrated
                  in their hands, clients were asked to commit to all 15 demands; industry organizations who weren’t clients were asked to
                  commit to 12.
                </p>
                <p>
                  <strong>
                    <a
                      href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRc-37OEYwRUOX_Tu7tJVGeBZH44uT7g3X15RbiTXdqUa32tDGexAQZcsoncqB4-kLYUXihiEsGZfJe/pubhtml?gid=1769330560&single=true"
                      target="_blank"
                      rel="noreferrer"
                    >
                      630+
                    </a>
                  </strong>{" "}
                  BIPoC advertising and marketing professionals and allies signed the demands. <strong>{orgsAlpha.length}</strong> industry
                  organizations signed the commitment; more are signing every day.
                </p>
              </div>

              <ul className="list-inline mb-0">
                <li className="list-inline-item mb-3">
                  <p className="mb-1">Agency and Client Leaders</p>
                  <a
                    className="btn btn-primary btn-lg mr-3 mb-2"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfPtVY0Ew6Cgyhzriv4Op4tnWiZ8B9K6ZHLyDjLfpuOYAqZHA/viewform"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Commit to The Call for Equity
                  </a>
                </li>
                <li className="list-inline-item">
                  <p className="mb-1">DEI Leader or DEI Champion</p>

                  <Link as={"a"} to={"/update-status"} className="btn btn-secondary btn-lg mb-2">
                    Update Progress
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-4 py-lg-5">
        <Container>
          <Row id="call-for-equity-tracker" className="justify-content-center">
            <Col>
              <Row className="position-relative z-1">
                <Col md="8">
                  <h2 className="mb-3">Call for Equity Progress Tracker</h2>
                  <p>
                    POCAM’s Call for Equity Tracker is a public record of the progress made against those commitments. It's a way to
                    celebrate and share progress and to support, encourage or challenge those who have not progressed yet.
                  </p>
                </Col>
              </Row>
              <CardDeck className="mb-4 cd-xs-col-2">
                <Card className="card-btn" onClick={() => handleSort("reset")}>
                  <Card.Body className="text-center">
                    <h3>{dashboardData.signed}</h3>
                    organizations have signed the Call for Equity
                  </Card.Body>
                </Card>

                <Card className="card-btn bg-light" onClick={() => handleSort("registered")}>
                  <Card.Body className="text-center">
                    <h3>{dashboardData.registered.length}</h3>
                    organizations have registered for the Progress Tracker
                  </Card.Body>
                </Card>

                <Card className="card-btn bg-secondary text-white" onClick={() => handleSort("c1c2completes")}>
                  <Card.Body className="text-center">
                    <h3>{dashboardData.c1c2completes.length}</h3>
                    organization{dashboardData.c1c2completes.length > 1 ? "s have" : " has"} publicly shared their commitment and annual
                    diversity data
                  </Card.Body>
                </Card>

                <Card className="card-btn bg-success text-white" onClick={() => handleSort("complete")}>
                  <Card.Body className="text-center">
                    <h3>{dashboardData.complete.length}</h3>
                    organization{dashboardData.complete.length > 1 ? "s have" : " has"} reported all commitments complete
                  </Card.Body>
                </Card>
              </CardDeck>

              <Row className="position-relative z-1 pb-4">
                <Col md="8">
                  <p>
                    Select an organization below to view their progress against the Call for Equity commitments or register as your
                    organization DEI Leader or DEI Champion to <Link to="/update-status/">Update Progress</Link>
                  </p>
                </Col>
              </Row>
              <ListGroup className="list-group--sticky">
                <div className="list-group-item list-group-item-reduced list-group-sticky">
                  <Row className="list-group-header">
                    <Col xs="5" sm="6" xl="7">
                      <strong>Company</strong>
                      <br />
                      <small>Date Signed</small>
                    </Col>
                    <Col xs="3" className="text-center">
                      <strong>Completed</strong>
                    </Col>
                    <Col className="text-center">
                      <strong>Last Updated</strong>
                    </Col>
                  </Row>
                </div>

                {orgsSorted.map((item, index) => {
                  const org = slugify(item)
                  const orgData = orgsData[org] || null
                  const lastUpdated = orgsData[org] ? orgsData[org].timestamp : null
                  const date = orgLastUpdated(org)
                  let classes = ""

                  let completedCount = 0
                  const count = orgsData[org] && orgsData[org].agencyType === "agency" ? 12 : 15

                  if (dashboardData.complete.includes(item)) {
                    classes = "bg-success text-white"
                  } else if (dashboardData.c1c2completes.includes(item)) {
                    classes = "bg-secondary text-white"
                  } else if (orgData) {
                    classes = "bg-light"
                  }

                  if (orgData) {
                    Object.entries(orgData).forEach(([key, value]) => {
                      if (value === "completed") {
                        completedCount++
                      }
                    })
                  }

                  // const time =

                  // console.log(item)
                  // console.log(signatories)
                  // console.log(signatories[item])
                  // console.log(signatories[item][0])
                  if (orgData) {
                    return (
                      <ListGroup.Item
                        action
                        key={index}
                        onClick={() => handleClick(item)}
                        className={`list-group-item-reduced ${classes}`}
                        aria-current="true"
                      >
                        <Row>
                          <Col xs="5" sm="6" xl="7">
                            <strong>{item}</strong>
                            <br />
                            <small>{timestampToDate(signatories[item][0].timestamp)}</small>
                          </Col>
                          <Col xs="3" className="text-center">
                            {completedCount > 0 ? (
                              <strong>
                                {completedCount} / {count}{" "}
                              </strong>
                            ) : (
                              <> 0 / {count}</>
                            )}
                          </Col>
                          <Col className="text-center">{lastUpdated ? completedCount > 0 ? <strong>{date}</strong> : date : "--"}</Col>
                        </Row>
                      </ListGroup.Item>
                    )
                  }
                  // <li ><a href={`#${ string_to_slug(item)}`}>{item}</a></li>
                })}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </section>
      <SignUpComponent />
    </Layout>
  )
}

export default CallForEquityPage

export const query = graphql`
  query {
    allContentfulCallForEquityPage {
      edges {
        node {
          commitments {
            commitment {
              childMarkdownRemark {
                html
              }
            }
            otherField
          }
          timingOrCompletionCopy
        }
      }
    }
  }
`
